import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppThemeProvider } from '@twlvxtwlv/ui/src/components/shared/app-theme-provider'
import { appWithTranslation, useTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useState } from 'react'

const Dashboard = function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation('common')
  const [queryClient] = useState(() => new QueryClient())

  return (
    <>
      <Head>
        <title>{t('meta-title-default')}</title>
        <meta name="description" content={t('meta-description-default') ?? undefined} />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta property="og:image" content="/txt-logo.webp" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <AppThemeProvider>
            <Component {...pageProps} />
          </AppThemeProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default appWithTranslation(Dashboard) as any
